import * as moment from 'moment';

/**
 * Converts to title case.  Adapted from https://stackoverflow.com/a/22193094
 * @param val String
 * @returns val but in title case
 */
export const toTitleCase = (val?: string) =>
  val &&
  val
    .split(' ')
    .map((word) => `${word[0].toUpperCase()}${word.substr(1).toLowerCase()}`)
    .join(' ');

/**
 * Converts provided bytes number into human readable string
 * Source: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
 */
export const toFileSize = (size: number) => {
  if (size < 1024) {
    return size + 'bytes';
  } else if (size >= 1024 && size < 1048576) {
    return (size / 1024).toFixed(1) + 'KB';
  } else if (size >= 1048576) {
    return (size / 1048576).toFixed(1) + 'MB';
  }
};

export const convertNumberToUSFormat = (number: number) => {
  return new Intl.NumberFormat('en-US').format(number);
};

export const formatDateComparingToday = (date: moment.Moment) => {
  const now = moment();
  const calendarFormat = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: '[Next] dddd',
    lastDay: '[Yesterday]',
    lastWeek: 'dddd',
    sameElse: now.isSame(date, 'year') ? 'MMM D' : 'MMM D, YYYY'
  };

  return now.isSame(date, 'day') ? date.fromNow() : date.calendar(null, calendarFormat);
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
