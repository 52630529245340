export const CARDS_GATEWAY_PROVIDER_NAME = 'emburse_cards_gateway';
export const CARDS_GATEWAY_CARD_TYPE = 'corporate_card';
export const EMBURSE_CARDS_PROVIDER_NAME = 'emburse';

export const PROVIDER_VALUES = [
  'finicity',
  'plaid',
  'abacus',
  'capitalone',
  'emburse',
  'demo_data_provider',
  'manual_upload',
  CARDS_GATEWAY_PROVIDER_NAME
] as const;

export type CARD_PROVIDER = typeof PROVIDER_VALUES[number];

export enum INSTITUTION_TYPE {
  AMEX_CPG = 'amex_cpg',
  AMEX_FINS = 'amex_fins',
  AMEX_FILES = 'amex_files',
  EMBURSE_DEMO = 'emburse_demo',
  GRINGOTTS = 'gringotts',
  EMBURSE = 'emburse',
  EMBURSE_PROD = 'emburse_prod',
  FINICITY = 'finicity',
  CAPITAL_ONE = 'capital-one'
}

export const EMBURSE_ISSUED_CARDS = [
  INSTITUTION_TYPE.EMBURSE,
  INSTITUTION_TYPE.EMBURSE_DEMO,
  INSTITUTION_TYPE.GRINGOTTS,
  INSTITUTION_TYPE.EMBURSE_PROD
] as string[];
