import {
  Locale,
  add,
  addBusinessDays as fnsAddBusinessDays,
  addHours as fnsAddHours,
  differenceInDays as fnsDifferenceInDays,
  endOfDay as fnsEndOfDay,
  endOfMonth as fnsEndOfMonth,
  getDate as fnsGetDate,
  getDay as fnsGetDay,
  getHours as fnsGetHours,
  getMonth as fnsGetMonth,
  getTime as fnsGetTime,
  getYear as fnsGetYear,
  isDate as fnsIsDate,
  isPast as fnsIsPast,
  isToday as fnsIsToday,
  lastDayOfMonth as fnsLastDayOfMonth,
  nextDay as fnsNextDay,
  previousDay as fnsPreviousDay,
  setDate as fnsSetDate,
  startOfDay as fnsStartOfDay,
  startOfMonth as fnsStartOfMonth,
  subBusinessDays as fnsSubBusinessDays,
  format,
  formatDistanceStrict,
  formatDistanceToNow,
  formatISO,
  isWeekend,
  parse,
  parseISO,
  subDays,
  subHours,
  subMilliseconds,
  subMinutes,
  subMonths,
  subYears,
  toDate
} from 'date-fns';

import { MONTHNAME_DAY, MONTHNAME_PADDEDDAY_YEAR, MONTH_DAY_YEAR } from '../constants/abacus_time_formatter_strings';

type Day = 0 | 1 | 2 | 3 | 4 | 5 | 6;
interface TimePeriod {
  startDate?: number;
  endDate?: number;
}

const WEEKDAY_TO_NUMBER_MAPPING = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0
} as Record<string, Day>;

/**
 * converts date to a provided format
 *
 * @param {*} date
 * @param {string} [format='MMM dd, yyyy']
 * @param {boolean}
 * @return Date
 */
function formatDate(date: any, dateFormat: string = MONTHNAME_PADDEDDAY_YEAR) {
  try {
    if (typeof date === 'string') {
      return format(parseISO(date), dateFormat);
    }

    const res = format(date, dateFormat);
    return res;
  } catch (e) {
    return e.message;
  }
}

/**
 * Given a date and an optional dateFormat
 * @param date
 * @param dateFormat
 * @returns
 */
function toDateObject(date: any, dateFormat: string = MONTHNAME_PADDEDDAY_YEAR) {
  try {
    if (typeof date === 'string') {
      const dateInMs = parse(date, dateFormat, new Date());
      const parsedDate = toDate(dateInMs);

      return isValidDate(parsedDate) ? parsedDate : new Date(date);
    }

    return toDate(date);
  } catch (e) {
    return e.message;
  }
}

/**
 * refined variant of datefns isDate, tests if the object is a date object and if the object's .valueOf returns a number value (invalid Date is a date object with no ms value)
 * @param date
 * @returns
 */
function isValidDate(date: any) {
  return fnsIsDate(date) && isNaN(date.valueOf()) === false;
}

/**
 * Returns midnight of the date passed (removes the hrs, mins, sec, and ms from the time)
 * @param d Starting date
 * @returns The time at midnight for a particular day
 */
function getZeroTimeDate(d: Date) {
  return new Date(d.setHours(0, 0, 0, 0));
}

function getNoonDate(date: Date) {
  const zeroedDate = getZeroTimeDate(date);
  return new Date(zeroedDate.setHours(12));
}

function getNoonTimestamp(date: Date) {
  return Date.UTC(getNoonDate(date).getFullYear(), getNoonDate(date).getMonth(), getNoonDate(date).getDate(), getNoonDate(date).getHours());
}

function addDays(d: Date, days: number) {
  return add(d, { days: days });
}

function addMonths(d: Date, months: number) {
  return add(d, { months: months });
}

function isToday(d: Date) {
  return fnsIsToday(d);
}

function formatTimeAway(d: Date | number | string) {
  return formatDistanceToNow(typeof d === 'string' ? parseISO(d) : d, { addSuffix: true });
}

function formatTimeDistanceStrict(
  date1: Date | number | string,
  date2: Date | number | string,
  opts?: {
    addSuffix?: boolean;
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
    roundingMethod?: 'floor' | 'ceil' | 'round';
    locale?: Locale;
  }
) {
  const convertedDate1 = typeof date1 === 'string' ? parseISO(date1) : date1;
  const convertedDate2 = typeof date2 === 'string' ? parseISO(date2) : date2;
  return formatDistanceStrict(convertedDate1, convertedDate2, opts);
}

const formatTimeAgo = formatTimeAway;
const formatTimeFromNow = formatTimeAway;

function subtractMs(d: Date | number, minutes: number) {
  return subMilliseconds(d, minutes);
}
function subtractMinutes(d: Date | number, minutes: number) {
  return subMinutes(d, minutes);
}

function subtractHours(d: Date | number, hours: number) {
  return subHours(d, hours);
}

function subtractDays(d: Date | number, days: number) {
  return subDays(d, days);
}

function subtractMonths(d: Date | number, months: number) {
  return subMonths(d, months);
}

function subtractYears(d: Date | number, years: number) {
  return subYears(d, years);
}

/**
 * Returns the formatted date
 *
 * Given the current date is 2022-02-02
 * If the date is 2022-01-12 => returns Jan 12
 * If the date is 2021-01-12 => returns 01/12/2021
 *
 */
function formatDateOnly(date: Date) {
  const today = new Date();
  if (date.getUTCFullYear() === today.getUTCFullYear()) {
    return formatDate(date, MONTHNAME_DAY);
  }

  return formatDate(date, MONTH_DAY_YEAR.RAW);
}

function getCurrentYear() {
  return new Date().getFullYear();
}

function getDate(date: Date) {
  return fnsGetDate(date);
}

function getMonth(date: Date) {
  return fnsGetMonth(date);
}

function getYear(date: Date) {
  return fnsGetYear(date);
}

function getHours(date: Date | number) {
  return fnsGetHours(date);
}

function addHours(date: Date | number, hours: number): Date {
  return fnsAddHours(date, hours);
}
function getDay(date: Date | number) {
  return fnsGetDay(date);
}

function differenceInDays(date1: Date, date2: Date) {
  return fnsDifferenceInDays(date1, date2);
}

function startOfMonth(date: Date) {
  return fnsStartOfMonth(date);
}

function endOfMonth(date: Date) {
  return fnsEndOfMonth(date);
}

function startOfDay(date: Date) {
  return fnsStartOfDay(date);
}

function endOfDay(date: Date) {
  return fnsEndOfDay(date);
}
function getTime(date: Date) {
  return fnsGetTime;
}

function nextDay(date: Date, weekday: Day) {
  return fnsNextDay(date, weekday);
}

function previousDay(date: Date, weekday: Day) {
  return fnsPreviousDay(date, weekday);
}

function isPast(date: Date | number) {
  return fnsIsPast(date);
}

function setDate(date: Date | number, dayOfMonth: number): Date {
  return fnsSetDate(date, dayOfMonth);
}

function lastDayOfMonth(date: Date | number): Date {
  return fnsLastDayOfMonth(date);
}

function setMonthDateWithoutOverflow(date: Date, dayNumber: number) {
  const clonedDate = new Date(date.getTime());
  let returnDate = setDate(clonedDate, dayNumber);
  // we have overflow
  if (getMonth(returnDate) !== getMonth(clonedDate)) {
    returnDate = lastDayOfMonth(clonedDate);
  }
  return returnDate;
}

/*
  Get the last dayNumber of the month. eg getLastNumberDateOfMonth(5, new Date('2023-02-25')) will return Feb 5, 2023, 
  while getLastNumberDateOfMonth(5, new Date('2023-02-02')) will return Jan 5, 2023
*/
function getLastCycleByDate(dayNumber: number, currentTimeInMillis = new Date().getTime()) {
  const currentDate = new Date(currentTimeInMillis);
  let calculatedDate;
  if (getDate(currentDate) > dayNumber) {
    calculatedDate = setDate(currentDate, dayNumber);
  } else {
    const pastMonth = subMonths(currentDate, 1);
    calculatedDate = setMonthDateWithoutOverflow(pastMonth, dayNumber);
  }
  return startOfDay(calculatedDate);
}

function getStatementCycleDates(cycleDate: number, currentTimeInMillis = new Date().getTime()): TimePeriod {
  const statementDates: TimePeriod = {};
  let endDate = getLastCycleByDate(cycleDate, currentTimeInMillis);
  let startDate = getLastCycleByDate(cycleDate, endDate.getTime());
  statementDates.endDate = endOfDay(endDate).getTime();
  statementDates.startDate = startOfDay(addDays(startDate, 1)).getTime();
  return statementDates;
}

function isWeekendDate(date: Date) {
  return isWeekend(date);
}

function addBusinessDays(date: Date, days: number) {
  return fnsAddBusinessDays(date, days);
}

function subtractBusinessDays(date: Date, days: number) {
  return fnsSubBusinessDays(date, days);
}

function getTimesFromPeriod(dateRange: TimePeriod, offset: number = 100000) {
  return {
    within: [dateRange.startDate + 1, dateRange.startDate + offset, dateRange.endDate - offset, dateRange.endDate - 1],
    outside: [dateRange.startDate - 1, dateRange.startDate - offset, dateRange.endDate + offset, dateRange.endDate + 1]
  };
}

export {
  formatISO,
  formatDate,
  formatDateOnly,
  addMonths,
  addDays,
  isToday,
  formatTimeAgo,
  formatTimeFromNow,
  getZeroTimeDate,
  getNoonDate,
  getNoonTimestamp,
  subtractMs,
  subtractMinutes,
  subtractHours,
  subtractDays,
  toDateObject,
  isValidDate,
  formatTimeDistanceStrict,
  getCurrentYear,
  subtractMonths,
  subtractYears,
  getDate,
  getMonth,
  getYear,
  differenceInDays,
  startOfMonth,
  endOfMonth,
  startOfDay,
  endOfDay,
  getTime,
  nextDay,
  previousDay,
  WEEKDAY_TO_NUMBER_MAPPING,
  Day,
  isPast,
  getLastCycleByDate,
  getStatementCycleDates,
  setDate,
  setMonthDateWithoutOverflow,
  TimePeriod,
  getDay,
  getHours,
  isWeekendDate,
  addHours,
  addBusinessDays,
  subtractBusinessDays,
  getTimesFromPeriod
};
