import { configure } from '@castleio/castle-js';

const isCastleEnabled = window.castlePublishableKey;
export const castle = isCastleEnabled ? configure({ pk: window.castlePublishableKey }) : null;

export function injectCastleRequestToken(event: any) {
  if (castle) {
    // @ts-ignore
    typeof castle.injectTokenOnSubmit === 'function' && castle.injectTokenOnSubmit(event);
  }
}
