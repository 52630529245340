export enum IMPLEMENTATION_OPTION_TYPES {
  SELF_GUIDED = 'self_guided',
  GUIDED = 'guided'
}
export const IMPLEMENTATION_OPTION_VALUES = Object.values(IMPLEMENTATION_OPTION_TYPES);

export enum TAX_EXEMPT_STATUS {
  EXEMPT = 'exempt',
  REQUESTED = 'requested',
  NON_EXEMPT = 'non_exempt'
}
export const TAX_EXEMPT_STATUS_VALUES = Object.values(TAX_EXEMPT_STATUS);

export const COMPANY_STATES = ['active', 'closed', 'suspended', 'billing_suspended', 'pending', 'deleted'];
