const USER_EMAIL_KEY = 'userEmail';

export function getUserEmailFromSessionStorage() {
  return sessionStorage.getItem(USER_EMAIL_KEY);
}

export function removeUserEmailFromSessionStorage() {
  return sessionStorage.removeItem(USER_EMAIL_KEY);
}

export function setUserEmailToSessionStorage(email: string) {
  return sessionStorage.setItem(USER_EMAIL_KEY, email);
}
