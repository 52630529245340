import * as React from 'react';

import * as ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';

import { emburseLoginEnabled } from '@Components/Login/loginHelper';

import { LoginPage } from '@Client/components/Login/LoginPage';
import { AbacusEmbarkProvider } from '@Shared/AbacusEmbarkProvider';

import '../client_react/src/utils/castle';

export interface WindowErrors {
  error: string;
  flashError: string;
  flashSuccess: string;
}

interface LoginWrapperWindow extends Window {
  csrf: string;
  errors: WindowErrors;
}

declare const window: LoginWrapperWindow;

function redirectToAccounts() {
  const wc = window.whitelabelConfiguration;
  window.location.href =
    `${wc.account_url}authorize?response_type=id_token` +
    `&client_id=${wc.account_client_id}&redirect_uri=${wc.host}/login/oidc/assertion` +
    `&scope=openid%20email%20licenses&nonce=${uuid()}`;
}

// reset sidebar expanded menu states
sessionStorage.removeItem('expandedMenuIds');

if (window.FEATURES?.emburse_login && emburseLoginEnabled(window.location?.search)) {
  redirectToAccounts();
}

// Set the public path for webpacked assets
// https://webpack.js.org/guides/public-path/
__webpack_public_path__ = window.webpack_public_path;
ReactDOM.render(
  <AbacusEmbarkProvider>
    <LoginPage csrf={window.csrf} locals={window.errors} />
  </AbacusEmbarkProvider>,
  document.querySelector('#app')
);
