import * as React from 'react';

import { Typography } from '@emburse/embark-core';

export const PrivacyPolicy: React.FC = () => {
  const policyFragment = window.whitelabelConfiguration?.fragments?.privacyPolicy;

  if (!policyFragment) {
    return null;
  }

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <div dangerouslySetInnerHTML={{ __html: policyFragment }} />
    </Typography>
  )
};
