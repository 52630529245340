// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7\\+nAm4\\+BVetF2JboLVhJCw\\=\\= input{margin:0 !important;padding:23px !important}.container-whitelabel-svb{padding-left:0 !important}", "",{"version":3,"sources":["/app/client/components/Login/LoginPage.styl"],"names":[],"mappings":"AACE,qCACE,mBAAQ,CACR,uBAAS,CAIb,0BACE,yBAAc","file":"LoginPage.styl","sourcesContent":[":local(.global) {\n  input {\n    margin: 0 !important;\n    padding: 23px !important;\n  }\n}\n\n.container-whitelabel-svb {\n  padding-left: 0px !important;\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"global": "_7+nAm4+BVetF2JboLVhJCw=="
};
module.exports = ___CSS_LOADER_EXPORT___;
