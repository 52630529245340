import * as React from 'react';

import * as cn from 'classnames';

import { Icon, Spacer, Typography } from '@emburse/embark-core';
import { AccountCircleIcon } from '@emburse/embark-icons';

export const AccountIconWithEmail: React.FC<{ className?: string; email: string }> = ({ className, email }) => (
  <div className={cn('view-email', 'flex', className)}>
    <Icon iconUrl={AccountCircleIcon} />
    <Spacer size={10} direction="horizontal" />
    <Typography variant="body1">{email}</Typography>
  </div>
);
