export const holidays = [
  // 2022
  '2022-01-01', // New Year's Day
  '2022-01-17', // Martin Luther King Day
  '2022-02-21', // Presidents Day
  '2022-05-30', // Memorial Day
  '2022-06-19', //Junteenth National Independence Day
  '2022-07-04', // Independence Day
  '2022-09-05', // Labor Day
  '2022-10-10', // Columbus Day
  '2022-11-11', // Veterans' Day
  '2022-11-24', // Thanksgiving Day
  '2022-12-25', // Christmas Day

  // 2023
  '2023-01-01', // New Year's Day
  '2023-01-16', // Martin Luther King Day
  '2023-02-20', // Presidents Day
  '2023-05-29', // Memorial Day
  '2023-06-19', //Junteenth National Independence Day
  '2023-07-04', // Independence Day
  '2023-09-04', // Labor Day
  '2023-10-09', // Columbus Day
  '2023-11-11', // Veterans' Day
  '2023-11-23', // Thanksgiving Day
  '2023-12-25', // Christmas Day

  // 2024
  '2024-01-01', // New Year's Day
  '2024-01-15', // Martin Luther King Day
  '2024-02-19', // Presidents Day
  '2024-05-27', // Memorial Day
  '2024-06-19', //Junteenth National Independence Day
  '2024-07-04', // Independence Day
  '2024-09-02', // Labor Day
  '2024-10-14', // Columbus Day
  '2024-11-11', // Veterans' Day
  '2024-11-28', // Thanksgiving Day
  '2024-12-25', // Christmas Day

  // 2025
  '2025-01-01', // New Year's Day
  '2025-01-20', // Martin Luther King Day
  '2025-02-17', // Presidents Day
  '2025-05-26', // Memorial Day
  '2025-06-19', //Junteenth National Independence Day
  '2025-07-04', // Independence Day
  '2025-09-01', // Labor Day
  '2025-10-13', // Columbus Day
  '2025-11-11', // Veterans' Day
  '2025-11-27', // Thanksgiving Day
  '2025-12-25', // Christmas Day

  // 2026
  '2026-01-01', // New Year's Day
  '2026-01-19', // Martin Luther King Day
  '2026-02-16', // Presidents Day
  '2026-05-25', // Memorial Day
  '2026-06-19', //Junteenth National Independence Day
  '2026-07-04', // Independence Day
  '2026-09-07', // Labor Day
  '2026-10-12', // Columbus Day
  '2026-11-11', // Veterans' Day
  '2026-11-26', // Thanksgiving Day
  '2026-12-25' // Christmas Day
];
