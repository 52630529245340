import * as React from 'react';
import * as style from './Disclosure.styl';
import { IWhitelabelConfiguration } from '@Shared/interfaces';

interface DisclosureProps {
  whitelabelConfiguration: IWhitelabelConfiguration;
}

export const Disclosure: React.FC<DisclosureProps> = (props) => {
  if (
    !(props.whitelabelConfiguration && props.whitelabelConfiguration.fragments && props.whitelabelConfiguration.fragments.onboardingFooter)
  ) {
    return null;
  }

  return <div className={style.global} dangerouslySetInnerHTML={{ __html: props.whitelabelConfiguration.fragments.onboardingFooter }} />;
};
