import * as React from 'react';

import { Typography } from '@emburse/embark-core';

interface IExtendedWindow extends Window {
  featureFlags: any;
}

export const TestSiteBanner: React.FC = () => {
  if ((window as unknown as IExtendedWindow)?.FEATURES?.test_site) {
    return (
      <Typography variant="h6" style={{ color: 'red' }} gutterBottom>
        THIS IS A TEST WEBSITE. PLEASE DON'T USE YOUR REAL CREDENTIALS TO LOGIN
      </Typography>
    )
  }

  return null;
};
