// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V9x4fnKIFfFuhzJfvCk0VQ\\=\\={display:flex;justify-content:center;}.V9x4fnKIFfFuhzJfvCk0VQ\\=\\= .MuiInputBase-root{height:42px}.V9x4fnKIFfFuhzJfvCk0VQ\\=\\= .logoContainer{display:flex;justify-content:flex-start;margin:auto;padding:8rem 0 0;max-width:570px;text-align:center}", "",{"version":3,"sources":["/app/client_react/src/components/Shared/Account/EmburseAccountsLeftContentContainer.styl"],"names":[],"mappings":"AAAA,4BACE,YAAS,CACT,sBAAiB,CAAA,CAEjB,+CACE,WAAQ,CAGV,2CACE,YAAS,CACT,0BAAiB,CACjB,WAAQ,CACR,gBAAS,CACT,eAAW,CACX,iBAAY","file":"EmburseAccountsLeftContentContainer.styl","sourcesContent":[":local(.global) {\n  display: flex;\n  justify-content: center;\n\n  .MuiInputBase-root {\n    height: 42px;\n  }\n\n  .logoContainer {\n    display: flex;\n    justify-content: flex-start;\n    margin: auto;\n    padding: 8rem 0 0; // 128px 0 0\n    max-width: 570px;\n    text-align: center;\n  }\n}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"global": "V9x4fnKIFfFuhzJfvCk0VQ=="
};
module.exports = ___CSS_LOADER_EXPORT___;
