import { WhitelabelId } from '../interfaces/IWhitelabelConfiguration';

export const DEFAULT_WHITELABEL_CONFIGURATION_ID: WhitelabelId = 'ABACUS';
export const EMBURSE_SPEND_WHITELABEL_ID: WhitelabelId = 'EMBURSE_SPEND';
export const REACH_WHITELABEL_ID: WhitelabelId = 'EMBURSE_REACH';
export const SVB_WHITELABEL_ID: WhitelabelId = 'SVB';

export const EDITABLE_WHITELABEL_IDS = [DEFAULT_WHITELABEL_CONFIGURATION_ID, EMBURSE_SPEND_WHITELABEL_ID];

export const ALL_WHITELABEL_IDS = [
  DEFAULT_WHITELABEL_CONFIGURATION_ID,
  EMBURSE_SPEND_WHITELABEL_ID,
  REACH_WHITELABEL_ID,
  SVB_WHITELABEL_ID
];

export type WHITELABEL_ID = typeof ALL_WHITELABEL_IDS[number];
