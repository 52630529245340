const CSV_FIELDS = {
  CARD_NAME: { label: 'card name (21 character max)', name: 'Card name', key: 'cardName' },
  EMAIL: { label: 'cardholder email', name: 'Email', key: 'email' },
  BUDGET: { label: 'card budget', name: 'Budget', key: 'budget' },
  FREQUENCY: {
    label: `"card replenishment frequency ('non-recurring', 'daily', 'weekly', 'monthly')"`,
    name: 'Frequency',
    key: 'frequency'
  },
  RECIPIENT_NAME: { label: 'Shipping Recipient Name', name: 'Recipient name', key: 'recipient' },
  STREET_ADDRESS: { label: 'Shipping Street Address', name: 'Street Address', key: 'street' },
  ADDRESS_CITY: { label: 'Shipping Address City', name: 'Address City', key: 'city' },
  STATE: { label: 'Shipping State', name: 'State', key: 'state' },
  ZIP_CODE: { label: 'Shipping Zip Code', name: 'Zip Code', key: 'zipCode' }
} as const;

export { CSV_FIELDS };
