export enum CARD_ISSUING_ERRORS {
  EXISTING_PHYSICAL_CARD = 'EXISTING_PHYSICAL_CARD',
  BUDGET_MUST_BE_POSITIVE = 'Total Budget must be greater than $0.00',
  NO_PHONE_NUMBER = 'NO_PHONE_NUMBER',
  INVALID_USER_ADDRESS = 'INVALID_USER_ADDRESS',
  CARDHOLDER_STATUS_DEACTIVATED = 'CARDHOLDER_STATUS_DEACTIVATED',
  CARDHOLDER_STATUS_PENDING = 'CARDHOLDER_STATUS_PENDING'
}

export const VIRTUAL_CARD_ICON = 'img/icons/virtual_card.svg';
export const PHYSICAL_CARD_ICON = 'img/icons/physical_card.svg';
export const BULK_ORDER_PHYSICAL_CARD_ICON = 'img/icons/bulk_order_physical_card.svg';
