import { VendorWithTerms } from 'shared/interfaces';

import { IVendorBillModel, IVendorModel } from '../models';

export const vendorStateToText: Record<IVendorModel['state'], string> = {
  active: 'Active',
  disabled: 'Inactive'
};

export const VENDOR_STATE = {
  ACTIVE: 'active',
  DISABLED: 'disabled'
} as const;

export enum VENDOR_BILL_STATE {
  CREATED = 'created',
  APPROVED = 'approved',
  DECLINED = 'declined',
  DELETED = 'deleted',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  ISSUED = 'issued',
  AUTHORIZED = 'authorized',
  PARTIALLY_COMPLETED = 'partially_completed',
  COMPLETED = 'completed',
  EXPIRED = 'expired'
}

export enum BILL_PAY_SOURCE {
  QUICKBOOKS_ONLINE = 'quickbooks_online'
}

export const VENDOR_BILL_PAY_EXPIRE_CARD_DAYS = 14;

export enum TERMS_TYPE {
  STANDARD = 'STANDARD',
  DATE_DRIVEN = 'DATE_DRIVEN'
}

export const VENDOR_PAYMENT_TERM_DEFAULT_DAYS = 30;

export const BILL_CSV_FIELDS: Record<string, { column_name: keyof IVendorBillModel; required: boolean }> = {
  'Bill ID': { column_name: 'external_id', required: false },
  'Bill Number': { column_name: 'invoice_no', required: true },
  'Bill Date': { column_name: 'bill_date', required: true },
  Category: { column_name: 'category', required: false },
  'Vendor ID': { column_name: 'vendor_id', required: true },
  'Amount Subtotal': { column_name: 'amount_subtotal', required: true },
  'Amount Tax': { column_name: 'amount_tax', required: true },
  'Amount Total': { column_name: 'amount_total', required: true },
  Currency: { column_name: 'currency', required: false }
};

export type BILL_EXPORT_CSV_HEADERS =
  | 'Invoice Date'
  | 'Vendor ID'
  | 'Vendor Name'
  | 'Invoice #'
  | 'Issued Date'
  | 'Payment Date'
  | 'Payment Method'
  | 'Card Number'
  | 'Payment Status'
  | 'Due Date'
  | 'Status'
  | 'Amount';

export const BILL_EXPORT_CSV_FIELDS: Record<BILL_EXPORT_CSV_HEADERS, keyof IVendorBillModel> = {
  'Invoice Date': 'created_at',
  'Vendor ID': 'vendor_id',
  'Vendor Name': 'vendor',
  'Invoice #': 'invoice_no',
  'Issued Date': 'linked_account',
  'Payment Date': 'linked_account',
  'Payment Method': 'linked_account',
  'Card Number': 'linked_account',
  'Payment Status': 'state',
  Status: 'state',
  'Due Date': 'due_date',
  Amount: 'amount_total'
} as const;

export const VENDOR_PAYMENT_TERMS_DAYS_LABEL = 'Vendor Payment Terms Days';
export const VENDOR_PAYMENT_TERMS_TYPE_LABEL = 'Vendor Payment Terms Type';

export const VENDOR_CSV_FIELDS: Record<
  string,
  {
    column_name: keyof VendorWithTerms;
    required: boolean;
    allowed_values?: string[];
  }
> = {
  'Vendor ID': { column_name: 'id', required: true },
  'Vendor Name': { column_name: 'name', required: true },
  'Vendor Email': { column_name: 'email', required: true },
  'Vendor Street': { column_name: 'street_address', required: false },
  'Vendor City': { column_name: 'address_city', required: false },
  'Vendor State': { column_name: 'address_state', required: false },
  'Vendor Zip Code': { column_name: 'postal_code', required: false },
  'Vendor Website': { column_name: 'website', required: false },
  'Vendor Currency': { column_name: 'currency', required: false, allowed_values: ['USD'] },
  [VENDOR_PAYMENT_TERMS_DAYS_LABEL]: { column_name: 'vendor_payment_terms_days', required: true },
  [VENDOR_PAYMENT_TERMS_TYPE_LABEL]: {
    column_name: 'vendor_payment_terms_type',
    required: true,
    allowed_values: [TERMS_TYPE.STANDARD, TERMS_TYPE.DATE_DRIVEN]
  },
  'Vendor Payment Terms Discount Days': { column_name: 'vendor_payment_terms_discount_days', required: false },
  'Vendor Payment Terms Discount Type': {
    column_name: 'vendor_payment_terms_discount_type',
    required: false,
    allowed_values: [TERMS_TYPE.STANDARD, TERMS_TYPE.DATE_DRIVEN]
  },
  'Vendor Payment Terms Discount Percentage': { column_name: 'vendor_payment_terms_discount_perc', required: false },
  'Vendor Status': { column_name: 'state', required: true, allowed_values: [VENDOR_STATE.ACTIVE] }
};
