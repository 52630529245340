import { IWhitelabelConfiguration } from '@Shared/interfaces';

import { toTitleCase } from '../helpers/textFormatter';
import { IGenericOption } from './constants';

export const USER_ROLE: string = 'user';
export const ADMIN_ROLE: string = 'admin';
export const MANAGER_ROLE: string = 'manager';

export const USER_PROFILE_ROLE_VALUES: ReadonlyArray<string> = [USER_ROLE, ADMIN_ROLE, MANAGER_ROLE] as const;
export type USER_PROFILE_ROLES = typeof USER_PROFILE_ROLE_VALUES[number];

const USER_PROFILE_ROLE_TO_LABEL_MAPPING: Record<string, string> = {
  [USER_ROLE]: 'Member',
  [ADMIN_ROLE]: toTitleCase(ADMIN_ROLE),
  [MANAGER_ROLE]: toTitleCase(MANAGER_ROLE)
};

const USER_PROFILE_ROLE_DESCRIPTION: Record<string, string> = {
  [USER_ROLE]: 'Can submit expenses and request/ access cards',
  [ADMIN_ROLE]: 'Can configure controls and policies, invite users, setup accounting sync, approve/ deny expenses, and issue cards',
  [MANAGER_ROLE]: 'Reviews and approves expenses of members on their team'
};

export function getUserProfileRoleName(role: string, whitelabelConfig: IWhitelabelConfiguration) {
  return whitelabelConfig.roleNames[role] || USER_PROFILE_ROLE_TO_LABEL_MAPPING[role];
}

export interface IUserProfileRoleOptions extends IGenericOption {
  description?: string;
}

export function getUserProfileRoleOptions(whitelabelConfig: IWhitelabelConfiguration) {
  return Object.keys(USER_PROFILE_ROLE_TO_LABEL_MAPPING).map((role) => {
    return {
      value: role,
      label: getUserProfileRoleName(role, whitelabelConfig),
      description: USER_PROFILE_ROLE_DESCRIPTION[role]
    };
  });
}

export const INVITED_STATE = 'invited';
export const ACTIVE_STATE = 'active';
export const DISABLED_STATE = 'disabled';
export const DECLINED_STATE = 'invitation_declined';

export const USER_PROFILE_STATE_VALUES = [INVITED_STATE, ACTIVE_STATE, DISABLED_STATE, DECLINED_STATE] as const;
export type USER_PROFILE_STATE = typeof USER_PROFILE_STATE_VALUES[number];

export const ABACUS = 'abacus';
export const LEVER = 'lever';
export const API = 'api';
export const WHITELABEL_API = 'whitelabel_api';
export const SLACK = 'slack';
export const ZENEFITS = 'zenefits';
export const INVITE = 'invite';
export const INITIAL_SIGNUP = 'initial_signup';
export const ADDITIONAL_COMPANY_SIGNUP = 'additional_company_signup';

export const INVITATION_SOURCE_VALUES = [
  ABACUS,
  LEVER,
  API,
  WHITELABEL_API,
  SLACK,
  ZENEFITS,
  INVITE,
  INITIAL_SIGNUP,
  ADDITIONAL_COMPANY_SIGNUP
] as const;
export type INVITATION_SOURCE = typeof INVITATION_SOURCE_VALUES[number];
