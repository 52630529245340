export const CARD_REQUEST_ACTIVITY_STATE = {
  ISSUED: 'issued',
  DECLINED: 'declined',
  CANCELLED: 'cancelled',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  MANAGER_APPROVAL: 'manager_approval',
  ADMIN_APPROVAL: 'admin_approval'
} as const;

export const CARD_REQUEST_TERMINAL_ACTIVITIES = [
  CARD_REQUEST_ACTIVITY_STATE.ISSUED,
  CARD_REQUEST_ACTIVITY_STATE.DECLINED,
  CARD_REQUEST_ACTIVITY_STATE.CANCELLED
] as const;
export const CARD_REQUEST_ACTION_ACTIVITIES = [
  CARD_REQUEST_ACTIVITY_STATE.SUBMITTED,
  CARD_REQUEST_ACTIVITY_STATE.APPROVED,
  ...CARD_REQUEST_TERMINAL_ACTIVITIES
] as const;
export const CARD_REQUEST_ROUTING_ACTIVITIES = [
  CARD_REQUEST_ACTIVITY_STATE.MANAGER_APPROVAL,
  CARD_REQUEST_ACTIVITY_STATE.ADMIN_APPROVAL
] as const;
export const CARD_REQUEST_ACTIVITY_VALUES = [...CARD_REQUEST_ACTION_ACTIVITIES, ...CARD_REQUEST_ROUTING_ACTIVITIES];
export type CARD_REQUEST_ACTION_ACTIVITY_TYPE = typeof CARD_REQUEST_ACTION_ACTIVITIES[number];
export type CARD_REQUEST_ROUTING_ACTIVITY_TYPE = typeof CARD_REQUEST_ROUTING_ACTIVITIES[number];
export type CARD_REQUEST_ACTIVITY_TYPE = typeof CARD_REQUEST_ACTIVITY_VALUES[number];

export type CARD_REQUEST_TIME_PERIOD_TYPE = 'past' | 'current' | 'future';
