import { IDesignToken } from '@emburse/embark-core';

export const SIDEBAR_WIDTH = 290;
/**
 * Centralized theme provider for all components that use EmburseThemeProvider
 * This class should convert the Abacus style config to Emburse format and pass it off to the provider
 *
 * @export
 * @class AbacusThemeProvider
 */
class AbacusThemeProvider {
  /**
   * Returns Abacus Theme Options
   *
   * @static
   * @return {*}
   * @memberof AbacusThemeProvider
   */
  static getThemeOptions(): IDesignToken {
    const { whitelabelConfiguration } = window;
    return {
      palette: {
        monochrome: {
          main: whitelabelConfiguration?.tags?.secondaryColorCode
        },
        primary: {
          main: whitelabelConfiguration?.tags?.primaryColorCode
        },
        secondary: {
          main: whitelabelConfiguration?.tags?.secondaryColorCode
        }
      },
      sizes: {
        sidebar: {
          width: SIDEBAR_WIDTH
        }
      }
    };
  }
}

export { AbacusThemeProvider };
