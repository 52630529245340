import * as React from 'react';

import * as classNames from 'classnames';

import { Grid } from '@emburse/embark-core';

import * as style from './EmburseAccountsPageContainer.styl';

/**
 * A component designed to mimic the appearance of the Emburse Accounts login page
 * Shows content on the left and a stock image of a woman on the right
 * @param props.children The content to display on the left side of the page
 * @returns
 */
export const EmburseAccountsPageContainer: React.FC<{ className?: string; children: React.ReactNode }> = ({ className, children }) => {
  return (
    <Grid className={classNames(style.global, className)} container direction="row" justifyContent="center" style={{ height: '100%' }}>
      <Grid item lg={5} className="formGrid">
        {children}
      </Grid>
      <Grid item md={0} className="baseImageGrid">
        <div className="baseImageContainer">
          <img className="baseImage" src={'/img/login_background_image.jpg'} alt="background" />
        </div>
      </Grid>
    </Grid>
  );
};
