export const CARD_TX_STATE = {
  NEW: 'new',
  EXPENSED: 'expensed',
  SKIPPED: 'skipped',
  DELETED: 'deleted',
  DUPLICATE: 'duplicate',
  ADMIN_DRAFT: 'admin_draft'
} as const;

export const CARD_TX_STATE_VALUES = [
  CARD_TX_STATE.NEW,
  CARD_TX_STATE.EXPENSED,
  CARD_TX_STATE.SKIPPED,
  CARD_TX_STATE.DELETED,
  CARD_TX_STATE.DUPLICATE,
  CARD_TX_STATE.ADMIN_DRAFT
] as const;

export type CARD_TX_STATE_TYPE = typeof CARD_TX_STATE_VALUES[number];

export const CARD_TX_PROVIDER_STATE = {
  PENDING: 'pending',
  POSTED: 'posted',
  DECLINED: 'declined',
  VOIDED: 'voided'
} as const;

export const CARD_TX_PROVIDER_STATE_VALUES = [
  CARD_TX_PROVIDER_STATE.PENDING,
  CARD_TX_PROVIDER_STATE.POSTED,
  CARD_TX_PROVIDER_STATE.DECLINED,
  CARD_TX_PROVIDER_STATE.VOIDED
] as const;

export type CARD_TX_PROVIDER_STATE_TYPE = typeof CARD_TX_PROVIDER_STATE_VALUES[number];

// eslint-disable-next-line no-shadow
export enum CardTransactionScopes {
  NoPayments = 'noPayments'
}
