import * as React from 'react';

import * as classNames from 'classnames';

import * as logoContainerStyle from './LogoContainer.styl';

interface ILogoContainerProps {
  style?: React.CSSProperties;
  clientName?: string;
}

export const LogoContainer: React.FC<ILogoContainerProps> = ({ style, clientName }) => {
  return (
    <div className={classNames(logoContainerStyle.global, `wrapper-whitelabel-${window.whitelabelConfiguration?.id?.toLowerCase()}`)} style={style}>
      <a href="/">
        <img
          className={`img-whitelabel-${window.whitelabelConfiguration?.id?.toLowerCase()}`}
          src={window.whitelabelConfiguration?.tags?.appLogoPath}
          alt="logo"
        />
      </a>
      {window.whitelabelConfiguration?.tags?.whitelabelProductName && (
        <h2 className="brandname-subheader">{window.whitelabelConfiguration.tags.whitelabelProductName}</h2>
      )}
      {clientName && <span className="client-name"> + {clientName}</span>}
    </div>
  );
};
