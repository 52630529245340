// see https://date-fns.org/v2.29.3/docs/format for a comprehensive list of supported formats

/**
 * Month Day Year, e.g. jan 5 1990 -> 1/5/1990
 */
export const MONTH_DAY_YEAR = {
  RAW: 'M/d/y',
  WITH_PADDING: 'MM/dd/yyyy'
};

/**
 * Year Month Day, e.g. jan 5 1990 -> 1990-01-05
 */
export const YEAR_MONTH_DAY = {
  RAW: 'yyyy-MM-dd'
};
/**
 * Month (abbreviated) Day Year with padding 0s, e.g. jan 5 1990 -> jan 05, 1990
 */
export const MONTHNAME_PADDEDDAY_YEAR = 'MMM dd, yyyy';

/**
 * Month (abbreviated) Day Year with padding 0s, e.g. jan 5 1990 -> jan 05, 1990
 */
export const MONTHNAME_DAY_YEAR = 'MMM d, yyyy';

/**
 * Month (abbreviated) Day, e.g. jan 5 1990 -> jan 5
 */
export const MONTHNAME_DAY = 'MMM d';

/**
 * Hour Minute am/pm, e.g. 8am -> 8:00 AM
 */
export const HOUR_MINUTE_PERIOD = 'h:mm a';

/**
 * Long Localized Time e.g. jan 5 1990 8am -> January 5th 1990 8:00:00 AM
 */
export const LONG_LOCALIZED_DATETIME = 'PPP pp';

/**
 * Month (full) Day Year, e.g. jan 5 1990 -> january 5, 1990
 */
export const FULLMONTHNAME_DAY_YEAR = 'MMMM d, yyyy';

/**
 * Aug 09, 12:50 PM
 */
export const MONTH_DAY_HOUR_MINUTE_AM_PM = 'MMM dd, h:mm a';
