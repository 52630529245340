export const ACCOUNTING_CONNECTION_SERVICE_VALUES = [
  'quickbooks',
  'xero',
  'netsuite',
  'intacct',
  'manual',
  'quickbooks_webconnector'
] as const;
export type ACCOUNTING_CONNECTION_SERVICE_TYPES = typeof ACCOUNTING_CONNECTION_SERVICE_VALUES[number];

export const ACCOUNTING_CONNECTION_STATE_VALUES = ['active', 'canceled', 'error', 'deleted'];
